<template>
  <div>
    <div class="mt-0 p-2 SearchContainer" style="background-color: #f5f2f4">
      <div class="form-outline seacrh mt-3 mb-3">
        <input
          type="search"
          id="form1"
          class="form-control"
          :placeholder="$t('front.search')"
          v-model="search"
        />
      </div>
    </div>
    <div class="mainC">
      <div v-if="!isLoading" class="mainContainer">
        <div class="card" v-for="item in items" :key="item.id">
          <div class="textSide p-3 d-flex align-items-center">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <p
                  style="width: 200px"
                  class="purple mtavruli titleWidth newsHeaderTitle"
                  v-if="(lang = 'ka')"
                  v-html="item.titleKA"
                >
                  {{ item.titleKA }}
                </p>
                <p
                  style="width: 200px"
                  class="purple mtavruli titleWidth newsHeaderTitle"
                  v-else
                  v-html="item.titleEN"
                >
                  {{ item.titleEN }}
                </p>
              </div>

              <div class="col-12 blogText">
                <p
                  class="blackColor pr-5"
                  v-if="(lang = 'ka')"
                  v-html="removeHTML(subStringText(item.descriptionKA))"
                ></p>
                <p
                  class="blackColor pr-5"
                  v-else
                  v-html="removeHTML(subStringText(item.descriptionEN))"
                ></p>
                <div class="newsArch">
                  <router-link
                    class="btnArchive"
                    style="text-decoration: none"
                    :to="'/csr' + '/' + item.slug"
                  >
                    {{ $t("front.more") }}
                  </router-link>
                </div>
              </div>
              <div class="line mt-1"></div>
              <div v-if="(lang = 'ka')" class="col date mt-2 blackColor">
                {{ item.dateKA }}
              </div>
              <div v-else class="col date mt-2 blackColor">
                {{ item.dateEN }}
              </div>
            </div>
          </div>

          <div
            v-if="item.images"
            class="imgSide"
            :style="`backgroundImage: url(${server_url}/${item.images[0].image});`"
          ></div>
          <div class="imgSide" v-else>
            <iframe
              width="100%"
              height="100%"
              :src="item.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row g-2">
          <div
            class="col col-sm-6 mt-4 col-12"
            v-for="item in [1, 2, 3, 4]"
            :key="item"
          >
            <b-skeleton-img
              no-aspect
              height="18rem"
              type="input"
            ></b-skeleton-img>
          </div>
        </div>
      </div>
      <div
        class="d-flex col-12 align-itsmes-cenetr mt-5 justify-content-center"
      >
        <b-pagination
          v-if="items.length > 0"
          v-model="currentPage"
          :total-rows="paginationLength"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <div
        class="mb-5 ml-3 resultCont"
        style="text-transform: uppercase"
        v-if="items.length == 0 && isLoading == false"
      >
        <h5 style="text-transform: uppercase" class="mtavruli">
          {{ $t("front.didnotFind") }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import env from "../../../env.json";
import axios from "axios";
export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      perPage: 4,
      lang: "ka",
      paginationLength: "",
      isLoading: false,
      server_url: env.server_url,
      timeout: 500,
      timer: null,
    };
  },
  props: ["search"],
  watch: {
    search: function (newValue) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.currentPage = 1;
        this.perPage = 4;
        const valuedUrl = `${env.host}/search/csr/filter-by-pagination/${newValue}/${this.currentPage}/${this.perPage}`;
        const nonValuedUrl = `${env.host}/get/pagination/csr/null/${this.currentPage}/${this.perPage}`;
        axios.get(`${newValue ? valuedUrl : nonValuedUrl}`).then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        });
      }, this.timeout);
    },
    currentPage: function (newValue) {
      this.isLoading = true;
      axios
        .get(
          `${env.host}/get/pagination/csr/null/${this.currentPage}/${this.perPage}`
        )
        .then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  methods: {
    myFunction() {
      console.log(this.search);
    },
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    subStringText: function (string) {
      return (
        string.substring(0, 220).split("&nbsp;").join("").split("·&").join("") +
        "..."
      );
    },
  },

  mounted() {
    this.isLoading = true;
    axios
      .get(
        `${env.host}/get/pagination/csr/null/${this.currentPage}/${this.perPage}`
      )
      .then((result) => {
        this.items = [...result.data.item];
        this.paginationLength = result.data.total_page;
        this.isLoading = false;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.newsHeaderTitle >>> * {
  font-family: mtavruli;
  color: #7e5493 !important;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.mainC {
  max-width: 1400px;

  width: 100%;
  margin: auto;
  justify-content: center;

  min-height: calc(100vh - 190px);
  margin: auto;
}
.card {
  display: grid;
  border: 2px solid rgba(0, 0, 255, 0) !important;
  grid-template-columns: 1fr 1fr;
  height: 350px;
  max-height: 350px;
  align-items: center;
  margin: auto;
}
.textSide {
  width: 100%;
  height: 100%;
  background-color: #f5f2f4;
}

.blogText p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blogText {
  width: 200px;
}
.btnArchive {
  border: none;
  color: #7e5493;
  font-family: mtavruli;
  text-transform: uppercase;
}
.line {
  height: 1px;
  background-color: #4d4d4c;
  width: 95%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
}
.imgSide {
  background-color: #4d4d4c;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.date p {
  color: #4d4d4c;
}
.titleWidth {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.csrTitle >>> * {
  color: #7e5493;
  text-transform: uppercase;
}

.resultCont {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
}
::v-deep .page-link {
  color: #bdbdbd;
}
::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7e5493 !important;
  border-color: #7e5493 !important;
}
::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}
.form-outline {
  max-width: 1400px;
  margin: auto;
}
.form-control {
  border-radius: 0px;
}
.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
@media all and (max-width: 1500px) {
  .seacrh {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media all and (max-width: 1500px) {
  .mainC {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and (max-width: 1329px) {
  .newsArch {
    margin-right: 0px;
    float: none;
    margin-right: 0px;
    margin-top: 0px;
  }
}
@media all and (max-width: 960px) {
  .mainContainer {
    display: grid;
    grid-template-columns: 1fr;
  }
  /* .card {
    margin-bottom: 30px;
    max-height: 300px;
  } */
  .titleWidth {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media all and (max-width: 576px) {
  .card {
    display: grid;
    grid-template-columns: 1fr;
    height: 500px;
    max-height: 500px;
  }
  .imgSide {
    height: 200px;
    margin-top: -33px;
  }
  .line {
    width: 98%;
  }
}
</style>
<template>
  <div class="conatiner">
    <Navbar />

    <div class="mainBox mainContainer">
      <div class="cardsCont"><CsrCrds /></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";

import CsrCrds from "./CsrCards.vue";

export default {
  components: {
    Navbar,
    Footer,

    CsrCrds,
  },
};
</script>

<style scoped>
.conatiner {
  margin: auto;
}
</style>
